/**
 * Config Filter
 * @typedef {import('@/model/shared/Filter').default} FilterConfig
 */

/**
 * Filter added
 * @typedef {Object} FilterAdded
 * @property {string|number} value - Option selected or value  from trackBy
 * @property {object} meta - Real object selected
 * @property {FilterConfig} filterConfig
 */

import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { FILTER_LIST, QUICK_FILTERS, SAVE_FILTERS, SAVE_QUICK_FILTERS } from '@/store/modules/filters/keys';
import { USER } from '@/store/modules/auth/keys';
import { Toast } from '@/model/shared/Toast';
import { queryParamsMixin } from '@/mixins/common/queryParamsMixin';
/**
 * Load filter` option by id
 * @param { FilterAdded } filterAdded
 * @param {string} filterIndex this is filter index used when loading filter item return an error
 * @returns {Promise}
 */
function getItemAndUpdateFilter(filterAdded) {
  return new Promise(function (resolve, reject) {
    filterAdded.filterConfig
      ._getItemBy(filterAdded.meta)
      .then(data => {
        const item = data.data || data; // TODO only normalized services
        filterAdded.value = item[filterAdded.filterConfig.textBy];
        filterAdded.meta = item;
        resolve();
      })
      .catch(() => {
        filterAdded.value = null;
        filterAdded.meta = null;
        reject({ filterKey: filterAdded.filterConfig.key, message: `We can load ${filterAdded.filterConfig} filter.` });
      });
  });
}

/**
 * Make a query-string object for filter
 * @return {FilterAdded} Returns the filters as query string
 */
function getFiltersAsQueryObject(paramsFilters) {
  const query = {};
  paramsFilters.forEach(filter => {
    // If filter has been getting from store before get meta from endpoint,
    // value is  equal  null to show loading indicator on pills
    let value = getRealValue(filter);
    if (!value) return;
    const filterKey = `filter[${filter.filterConfig.key}]`;

    if (query[filterKey]) {
      value = `${query[filterKey]},${value}`;
    }

    query[filterKey] = value;
  });
  return query;
}

/**
 * Get value, meta value or meta[filterConfig.trackBy] value
 *
 * @param {FilterAdded} filter
 * @returns {string|undefined} Real value to send to service or to show on url
 */
function getRealValue(filter) {
  return filter.meta?.[filter.filterConfig.trackBy] || filter.value || filter.meta;
}

/**
 * @typedef FiltersMixin
 * @property {object} data
 * @property {FilterConfig[]} data.filter
 * @property {object.<string, string>} data.filterQuick
 * @property {object.<string, string>} data.filterFind
 * @property {FilterAdded[]} data.filterErrorInput
 * @property {object} computed
 * @property {FilterConfig[]} computed.availableFilters
 * @property {object} methods
 * @property {Function} methods.filtersLoadAllfiltersOnMounted
 * @property {Function} methods.filtersMakeFiltersForSelect
 * @property {Function} methods.filtersOnSelectFilter
 * @property {Function} methods.filtersResetErrors
 * @property {Function} methods.filtersSetFiltersFind
 * @property {Function} methods.filtersUpdateFiltersOnStoreAndURL
 * @property {Function} methods.filtersGetFilterError
 */

/**
 * You can set filter, array of Filter class,
 * and quick filters, object with key value format.
 * Import this before index mixin
 *
 * This property exists on data component
 *
 * @example new implement way
 * filtersMixin({
 *    filters: CONFIG.filters,
 *    filterQuick: {
 *      'deal.name': undefined,
 *    }
 * })
 *
 * if prefer traditional way, implement this mixin as filtersMixin()
 *
 * @param {object} config
 * @param {FilterConfig[]}  config.filters
 * @param {object<string, string>} config.filterQuick
 * @return {FiltersMixin}
 */
export default ({ filters = [], filterQuick = {} }) => ({
  data: () => ({
    filters,
    filterQuick: { ...filterQuick },
    /** @type {FilterAdded[]} */
    filterFind: [],
    filterDefault: [], // load from view
    filterErrorInput: {},
  }),
  mixins: [queryParamsMixin],
  computed: {
    ...mapGetters({
      storedFilters: FILTER_LIST,
      storedQuickFilters: QUICK_FILTERS,
    }),
    /**
     * This attribute is a Map where we found a key for each filter with a value regarding its filter type
     * @returns {Map<string, string>}
     */
    availableFilterTypes() {
      const types = new Map();
      this.filters.forEach(filter => {
        types.set(filter.key, 'filterFind');
      });
      // set quick filters
      Object.keys(this.filterQuick).forEach(key => {
        types.set(key, 'filterQuick');
      });

      return types;
    },

    /**
     * You can reviewing this method, using filtersAvailableFilters to get valid
     * filter.
     */
    availableFilters() {
      return this.filtersAvailableFilters;
    },
    filtersAvailableFilters() {
      const contextRoles = this.$store.getters[USER].contextRoles;
      const activeContextId = this.$store.getters[ACTIVE_CONTEXT]?.id;

      const rol = contextRoles.find(c => c.context === activeContextId)?.role;
      return this.filters.filter(f => f.isRolAllowed(rol));
    },
    anyError() {
      return Object.values(this.filterErrorInput).filter(value => !!value).length > 0;
    },
  },
  methods: {
    ...mapActions([SAVE_FILTERS, SAVE_QUICK_FILTERS, CREATE_TOAST]),
    filterOnFiltersFindChanged() {
      throw new Error('There is no method "filterOnFiltersFindChanged" in the view.');
    },
    async filtersOnActiveClientChange() {
      await this.filtersLoadAllfiltersOnMounted();
    },

    /**
     * Load all filters and update route
     * Load from query string, from store
     */
    async filtersLoadAllfiltersOnMounted(idView = this.$route.name) {
      // join all filters in to a map, the order is by property from min to max property.
      const allAddedFilters = new Map([
        ...this.storedFilters,
        ...this.filtersLoadQuickFilterFromStore(idView),
        ...this.filtersLoadFromQueryString(), // Load only view filters from URL
      ]);
      // Load only view filters from Store
      this.filterAddFiltersFromMapByType(allAddedFilters);

      // Load meta data on filters and update value properties
      await this.filtersGetItemByService();
      // Upload filter on url and store
      await this.filtersUpdateFiltersOnStoreAndURL(idView);

      await this.queryParamsRouterReplace();
    },
    /**
     * Load all filters and update route
     * Load from query string, from store
     */
    async filtersLoadFromStorefilters() {
      // join all filters in to a map, the order is by property from min to max property.
      const allAddedFilters = new Map([...this.storedFilters, ...this.filtersLoadQuickFilterFromStore()]);
      // Load only view filters from Store
      this.filterAddFiltersFromMapByType(allAddedFilters);

      // Load meta data on filters and update value properties
      await this.filtersGetItemByService();
      // Upload filter on url and store
      await this.filtersUpdateFiltersOnStoreAndURL();

      await this.queryParamsRouterReplace();
    },

    /**
     * Load view filters from Map object.
     * It takes each map entry and creates the corresponding filter.
     *  @param {Map<string, string>} map Contains a map entry for each filter. The key is the filter name
     */
    filterAddFiltersFromMapByType(map = new Map()) {
      map.forEach((valueOrList, key) => {
        if (!valueOrList) return;

        const filterType = this.availableFilterTypes.get(key);

        if (filterType === 'filterQuick') {
          this.$set(this.filterQuick, key, valueOrList);
        } //
        else if (filterType === 'filterFind') {
          /** @type {FilterConfig|null} */
          const filterAvailable = this.filters.find(f => f.key === key);

          // several values for same filter
          valueOrList.split(',').forEach((selectedValue, index) => {
            // This is not really necessary
            if (!selectedValue) return;

            if (index !== 0 && !filterAvailable.multiple) {
              this[CREATE_TOAST](
                Toast.error(
                  `We can't load  value for filter`,
                  `We can  load  only one value for '${filterAvailable}' filter.`
                )
              );
              return;
            }

            const newFilter = {
              filterConfig: filterAvailable,
              value: selectedValue,
              meta: undefined,
              name: filterAvailable.value,
              label: filterAvailable.name,
            };

            if (typeof filterAvailable._getItemBy === 'function') {
              newFilter.meta = selectedValue; // Backup for value
              newFilter.value = null; // If is null, filter pill will show loading tag
            }

            this.filterFind.push(newFilter);
          });
        } else {
          // TODO for release feature
          // throw Error(`Filter is not available with key ${key}`);
          // console.error(`Filter is not available with key ${key}`);
        }
      });
    },

    /**
     * Load only view filters from URL
     */
    filtersLoadFromQueryString() {
      const filtersByQuery = new Map();

      Object.entries(this.queryParams).forEach(keyValue => {
        const [key, value] = keyValue;
        const parse = /filter\[(.+)\]/.exec(key);
        if (parse?.[0]) filtersByQuery.set(parse[1], value);
      });

      return filtersByQuery;
    },

    /**
     * Load quick filters from store
     */
    filtersLoadQuickFilterFromStore(idView = this.$route.name) {
      const filters = new Map();

      Object.entries(this.storedQuickFilters(idView) || {}).forEach(keyValue => {
        const [key, value] = keyValue;
        filters.set(key, value);
      });

      return filters;
    },

    /**
     * Load filter selected value
     */
    filtersGetItemByService() {
      const promise = [];

      this.filterFind.forEach(filterAdded => {
        if (filterAdded.meta) {
          promise.push(getItemAndUpdateFilter(filterAdded));
        }
      });

      return new Promise(resolve => {
        Promise.all(promise)
          .then(resolve)
          .catch(error => {
            const filterIndex = this.filterFind.findIndex(filter => filter.filterConfig.key === error.filterKey);
            if (filterIndex > -1) this.filterFind.splice(filterIndex, 1);

            this[CREATE_TOAST](Toast.error(`We can't load filter`, error.message));
            resolve();
          });
      });
    },

    /**
     * Add filters on URL from added filters, filterFind and filterQuick
     * View call tihs method from updateFilters too
     */
    async filterUpdateParamsInURL() {
      // Delete all filters on URL
      Object.keys(this.queryParams).forEach(key => {
        if (/filter\[.+\]/.test(key)) {
          this.removeQueryParam(key);
        }
      });

      const filters = Object.entries(this.filterQuick)
        .map(entry => ({ filterConfig: { key: entry[0] }, value: entry[1] }))
        .concat(this.filterFind);

      if (filters.length) {
        this.addQueryParams(getFiltersAsQueryObject(filters));
      }

      await this.queryParamsRouterReplace();
    },

    /**
     * ##############################
     * ## Methods using on undexMixin, Shall we move to indexMixin?
     * ##############################
     */

    /*
     * This method creat a map for each filter key and join values by coma
     * This method is using on indexMixin
     */
    filtersMakeToRequest() {
      const mapTransformFilter = new Map();

      /** @param {{ name:string, value:string }} filter */
      function addFilter(filter) {
        const keyFilter = filter.name;
        if (mapTransformFilter.has(keyFilter)) {
          // join duplicate filters
          const oldFilterValue = mapTransformFilter.get(keyFilter);
          mapTransformFilter.set(keyFilter, `${oldFilterValue},${filter.value}`);
        } else {
          mapTransformFilter.set(keyFilter, filter.value);
        }
      }

      // Add up default filters
      this.filterDefault.forEach(addFilter);

      // Add up filters applied
      this.filterFind.forEach(filter => {
        addFilter({ name: filter.filterConfig.value, value: getRealValue(filter) });
      });

      // Add up quick filters added
      Object.entries(this.filterQuick).forEach(entity => {
        const [name, value] = entity;
        if (value) addFilter({ name, value });
      });
      return mapTransformFilter;
    },

    /*
     * This method is using on indexMixin
     */
    filtersMakeQueryString() {
      const query = { ...this.queryParams };

      // clear filters from query and not another params like as page
      Object.entries(query).forEach(keyValue => {
        const [key] = keyValue;
        if (/filter\[.+\]/.test(key)) delete query[key];
      });

      // Add up filters added from string, because this filter has a multiple
      // values if is necessary
      this.storedFilters.forEach((value, key) => {
        if (this.availableFilterTypes.has(key)) {
          query[`filter[${key}]`] = value;
        }
      });

      // Add up quick filters added
      Object.entries(this.filterQuick).forEach(entity => {
        const [key, value] = entity;
        if (value) query[`filter[${key}]`] = value;
      });

      return query;
    },

    /**
     * #########################
     * ## Methods using on views
     * #########################
     */

    /**
     * This method is using on view to set filter on SunFilterLayout.
     * @param {FilterConfig} filter
     * @param {string|{name:string,value:string}} $event
     * @param {function(value:string,meta:string):void}  onSelect - function onSelect from filter slot
     */
    filtersOnSelectFilter(filter, $event, onSelect) {
      this.$set(this.filterErrorInput, filter.key, undefined);
      if ($event === '') {
        this.$set(this.filterErrorInput, filter.key, 'Empty value not allowed.');
        return;
      }

      if (!filter.multiple && this.storedFilters.has(filter.key)) {
        this.$set(this.filterErrorInput, filter.key, 'Only one filter of this type can be applied.');
        return;
      }

      const valueText = $event?.[filter.textBy] || $event;
      onSelect(valueText, $event);
    },

    /**
     * This method is using on template filters
     * @param {Array<FilterAdded>} values - The values to transform
     * and some other selects needs a more complex object, which is given when it is false
     * @return { array } return array of selected items
     */
    filtersMakeFiltersForSelect(values = []) {
      if (!values.length) return null; // Avoid need two clicks to show selected value on SunSelect
      return values.map(filter => (filter.meta?.[filter.filterConfig.trackBy] ? filter.meta : filter.value));
    },

    filtersResetErrors() {
      this.filterErrorInput = {};
    },

    /**
     * This method is using on view to get filter error.
     * @param {FilterConfig} filter
     * @param {undefined|boolean} isDuplicate - Scope parameter
     * @returns {string} - Error message
     */
    filtersGetFilterError(filter, isDuplicate) {
      return isDuplicate ? 'Filter already added.' : this.filterErrorInput[filter.key] || '';
    },

    /**
     * View using this method on updateFilters method when filter is changed.
     */
    async filtersUpdateFiltersOnStoreAndURL(idView = this.$route.name) {
      const filterToSave = [];

      this.storedFilters.forEach((value, key) => {
        if (!this.availableFilterTypes.has(key)) {
          filterToSave.push({ filterConfig: { key }, value });
        }
      });

      this[SAVE_FILTERS](filterToSave.concat(this.filterFind));
      this[SAVE_QUICK_FILTERS]({ idView, filters: this.filterQuick });
      await this.filterUpdateParamsInURL();
    },

    /**
     * View using this method on updateFilters method when filter is changed.
     */
    async filtersSetFiltersFind(filters) {
      this.filterFind = filters;
      // When filters change get first page
      const newParams = { page: 1 };
      const { sort } = this.queryParams;
      if (sort) newParams.sort = sort;
      this.replaceQueryParams(newParams);

      // Update filters and url
      await this.filtersUpdateFiltersOnStoreAndURL();

      this.filterOnFiltersFindChanged();
    },
    resetFiltersBeforeDestroy() {
      // update filters
      this[SAVE_FILTERS]([]);

      this.filtersResetErrors();
    },
    resetFilters() {
      this[SAVE_FILTERS]([]);
      this.filtersSetFiltersFind([]);
      this.filtersResetErrors();
    },
  },
});
